<template>
  <div class="forgot">
    <h2>
      {{ $t('views.auth.forgot-password.recovery') }}
    </h2>

    <p>
      {{ $t('views.auth.forgot-password.description') }}
    </p>

    <form @submit.prevent="submit">
      <InputEmail
        :placeholder="`${$t('views.auth.forgot-password.email')}*`"
        :errors="form.errors.email"
        v-model="form.email"
      />

      <BlueButton
        class="forgot__button"
        type="submit"
        :loading="loading"
        :text="$t('views.auth.forgot-password.new-password')"
        :disabled="loading"
      />
    </form>

    <RouterLink
      :to="{
        name: 'SignIn',
        query: $route.query
      }"
      class="forgot__link"
    >
      {{ $t('views.auth.forgot-password.remembered') }}
    </RouterLink>
  </div>

  <AuthSupport />
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

import vClickOutside from 'click-outside-vue3'

import InputEmail from '@/ui/inputs/Email.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'
import AuthSupport from '@/ui/AuthSupport.vue'

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    BlueButton,
    InputEmail,
    AuthSupport
  },
  data () {
    return {
      loading: false,
      v: useVuelidate(),
      form: {
        email: '',

        errors: {
          email: []
        }
      }
    }
  },
  mounted () {
    if (this.$route.query.email) {
      this.form.email = this.$route.query.email
    }
  },
  validations () {
    return {
      form: {
        email: { required, email }
      }
    }
  },
  methods: {
    async submit () {
      this.loading = true
      this.v.form.$touch()
      this._setErrors()

      if (this._countError() === 0) {
        const formData = new FormData()
        formData.append('email', this.form.email)

        try {
          const resp = await this.$axios.post(
            '/auth/reset-password', formData)

          if (resp.status === 200) {
            this.$pop.up({
              name: 'emailPassword',
              email: this.form.email
            })

            this.form.email = ''
          }
        } catch (error) {
          if (!error.status) {
            this.form.errors.email.push(
              {
                type: 'text',
                text: this.$t('errors.email-notfound', { value: this.form.email })
              }
            )
          } else {
            throw new Error(error)
          }
        } finally {
          this.loading = false
        }
      }

      this.loading = false
    },
    _setErrors () {
      const errorsForm = this.v.form
      this.form.errors = {
        email: []
      }

      // Email
      if (errorsForm.email.required.$invalid) {
        this.form.errors.email.push('errors.required')
      }
      if (errorsForm.email.email.$invalid) {
        this.form.errors.email.push('errors.email')
      }
    },
    _countError () {
      const errors = this.form.errors
      var count = 0

      count += errors.email.length
      return count
    }
  }
}
</script>

<style lang="scss" scoped>
.forgot {
  width: 100%;
  max-width: 430px;
  margin-top: 50px;
  padding: 20px 20px 30px;
  background: #FFFFFF;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.06);
  border-radius: 15px;

  h2 {
    color: rgb(32, 37, 57);
    line-height: 100%;
    font-family: SemiBold;
  }

  p {
    margin: 20px 0;
    color: rgb(32, 37, 57);
    font-family: Medium;
    font-size: 14px;
    line-height: 100%;
  }

  .forgot__input {
    height: 46px;
    width: 100%;
    margin-bottom: 15px;
    background: #F0F2F8;
    border-radius: 5px;
  }

  .forgot__button {
    margin: 20px 0;
  }

  .forgot__link {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    color: rgb(47, 87, 233);
    font-family: SemiBold;
    font-size: 14px;
    line-height: 100%;
    text-transform: uppercase;
    text-decoration: none;
  }
}
</style>
